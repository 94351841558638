<template>
    <main>
        <HeaderTab :title="$t('localisation.title')" swiper_cle="localisation" :swiper_tabs="swiper_tabs"  />
        <router-view></router-view>
    </main>
</template>


<script type="text/javascript">

    export default {
        name: "Localisation",
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
        },
        data() {
            return {
                swiper_tabs: [{
                        label: 'localisation.gestion_localisation',
                        href : 'gestionLocalisation',
                        active: false
                    }, {
                        label: 'localisation.cartographie',
                        href : 'cartographie',
                        active: false
                    }
                ],
            }
        }
    }
</script>
